import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import { Search as SearchIcon } from "react-feather";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  InputBase,
  Button,
  Switch,
  Modal,
  Box,
  TextField,
  InputLabel,
  // Input,
  ButtonGroup,
  FormHelperText,
  FormControl,
  Snackbar,
  Backdrop,CircularProgress, Avatar
  // Button,
} from "@material-ui/core";
import { darken } from "polished";
import { green, red } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";

import { useSelector } from "react-redux";
import { Redirect, useHistory,useLocation } from "react-router-dom";
import { store } from "../../redux/store/index";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import { get, URL, headers, post ,langFun} from '../../services'
import { strToSlug, UniqueIdGen } from "../../utils/UniqueIdGen";
import { DropzoneDialog } from "material-ui-dropzone";
import RichTextEditor from "react-rte";
import { id } from "date-fns/locale";
const Divider = styled(MuiDivider)(spacing);
const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));


const style = {
  position: "absolute",
  padding: "40px",
  top: "50%",
  backgroundColor: "white",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  // bgcolor: 'background.paper',
  border: "1px solid rgb(17, 24, 39)",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  padding: "40px",
  top: "50%",
  backgroundColor: "white",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  // bgcolor: 'background.paper',
  border: "1px solid rgb(17, 24, 39)",
  boxShadow: 24,
  p: 4,
};
const Typography = styled(MuiTypography)(spacing);
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const MdBox = ({data,setmdData,index, mdData})=>{
    console.log('dataa md box',data)
    const [cen, setcen] = React.useState(RichTextEditor.createValueFromString(data.en,'html'));
    const [cde, setcde] = React.useState(RichTextEditor.createValueFromString(data.de,'html'));
    const [groups, setGroups] = useState([])
    const [subGroups, setsubGroups] = useState([])
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [group_id, setGroup_id] = useState('')
    const [sub_id, setSub_id] = useState('')
    const lang = useSelector(state => state.authReducer.lang)

    useEffect(() => {
       const mount = async() => {
         await getGroups()
        //  await getSubGroups()
       }
       mount()
    }, [])
   
    useEffect(() => {
       const mount = async() => {
         if(group_id) await getSubGroups()
       }
       mount()
    }, [group_id])

    useEffect(()=>{
      setcen(RichTextEditor.createValueFromString(data.en,'html'))
      setcde(RichTextEditor.createValueFromString(data.de,'html'))
    },[data])

    const DeleteItem = () =>{
        let temp=[...mdData]
        temp.splice(index,1)
        setmdData(temp)
    }

    const getGroups = async () =>{
        await get(URL.getgroup).then((res) => {
            if (res.status === true) {
                var array = []
                res.data.map((data, i) => {
                    var id = data._id
                    var name = data.name[lang]?data.name[lang]:data.name['en']
                    array.push({ id, name, data })
                })
                console.log('groups',array)
                setGroups(array)
              } 
        })
        .catch((error) => {
            console.log('onsubGroups',error)
          // alert(error.message)
          // setloader(false)
        })
      }

    const getSubGroups = async () =>{
        const obj = {
          offset:0,
          per_page:100,
          group_id:group_id,
          lang:'en'
        }
        await get(URL.getsubgroup,obj).then((response) => {
            if (response.status === true) {
              var array = []
              response.data.map((data, i) => {
                  var id = data._id
                  var name = data.name[lang]?data.name[lang]:data.name['en']
                  array.push({ id, name, data })
              })
              setsubGroups(array)
            } 
          })
          .catch((error) => {
              console.log('onsubGroups',error)
            // alert(error.message);
            // setloader(false)
          });
    }

    const handleProduct = (id)=>{
       let dataToSend =  {
            "group_id":group_id,
            "subgroup_ids":[id],
            "location":"UK",
            "offset":0,
            "per_page":100
        }
        console.log(dataToSend)
        setSub_id(id)
        post(URL.getproducts,dataToSend).then(res=>{
            if (res.status === true) {
                var array = []
                res.data.map((data, i) => {
                    var id = data._id
                    var name = data.name[lang]?data.name[lang]:data.name['en']
                    array.push({ id, name, data })
                })
                console.log('groups',array)
                setProducts(array)
              } 
            // setProducts(res.data)
        }).catch(err=>{
            console.log(err)
        })
    }

    const handlecen = (e)=>{
        let temp= [...mdData]
        temp[index].en = e.toString('html')
        setcen(e)
    }
    const handlecde = (e)=>{
        let temp= [...mdData]
        temp[index].de = e.toString('html')
        setcde(e)
    }
    const productHandle = (e)=>{
        let temp= [...mdData]
        temp[index].product = products[e]?.data
        setSelectedProduct(e)
    }

    return(
        <div className="container border my-3 p-10">
        <div className="row p-4">
            {index !== 0 && <Grid container justify="flex-end" >
                    <Grid item sx={4}>
                        <Button onClick={DeleteItem} variant="contained" fullWidth>Delete</Button>
                    </Grid>
            </Grid>}
            <div className="col-12">
                <p style={{color:"#233044"}}>Content Eng</p>
                <div className="mb-3">
                    <RichTextEditor
                        value={cen}
                        onChange={(e)=>handlecen(e)}
                    />
                    {/* <textarea value={ContentEn}  className="form-control"  onChange={(e) => setContentEn(e.target.value)}  style={{border:"1px solid #233044",padding:"10px"}} id="exampleFormControlTextarea1" rows="10"></textarea> */}
                </div>
        
                <p style={{color:"#233044",marginTop:"17px"}}>Content German</p>
                <div className="mb-3">
                    <RichTextEditor
                        value={cde}
                        onChange={(e)=>handlecde(e)}
                    />
                {/* <textarea value={ContentDe} className="form-control" onChange={(e) => setContentDe(e.target.value)}  style={{border:"1px solid #233044",padding:"10px"}} id="exampleFormControlTextarea1" rows="10"></textarea> */}
                </div>
                
                <p style={{color:"#233044",marginTop:"17px"}}>Product</p>
                <div className="mb-3">
                    <FormControl fullWidth >
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            Select Group
                        </label>
                        {/* <InputLabel  id="demo-simple-select-label">Choose Gropu for this Sub Group</InputLabel> */}
                        <select
                        onChange={(e) => setGroup_id(e.target.value)}
                        style={{ height: "36px", border: "1px solid #ced4da" }}
                        className="form-select"
                        aria-label="Default select example">
                        <option selected disabled>{group_id && "Choose Category"}</option>
                        {
                            groups.map((data, i) => {
                            return (
                                <option aria-required value={data.id}>{data.name}</option>
                            )
                            })
                        }
                        </select>
                </FormControl>
                {/* <textarea value={ContentDe} className="form-control" onChange={(e) => setContentDe(e.target.value)}  style={{border:"1px solid #233044",padding:"10px"}} id="exampleFormControlTextarea1" rows="10"></textarea> */}
                </div>
                <div className="mb-3">
                    <FormControl fullWidth >
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            SubGroups
                        </label>
                        {/* <InputLabel  id="demo-simple-select-label">Choose Gropu for this Sub Group</InputLabel> */}
                        <select
                        onChange={(e) => handleProduct(e.target.value)}
                        style={{ height: "36px", border: "1px solid #ced4da" }}
                        className="form-select"
                        aria-label="Default select example">
                         <option unselectable>{sub_id && "Choose Category"}</option>
                        {
                            subGroups.map((data, i) => {
                            return (
                                <option aria-required value={data.id}>{data.name}</option>
                            )
                            })
                        }
                        </select>
                </FormControl>
                {/* <textarea value={ContentDe} className="form-control" onChange={(e) => setContentDe(e.target.value)}  style={{border:"1px solid #233044",padding:"10px"}} id="exampleFormControlTextarea1" rows="10"></textarea> */}
                </div>
                <div className="mb-3">
                    <FormControl fullWidth >
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                            Select Product
                        </label>
                        {/* <InputLabel  id="demo-simple-select-label">Choose Gropu for this Sub Group</InputLabel> */}
                        <select
                        onChange={(e) => productHandle(e.target.value)}
                        style={{ height: "36px", border: "1px solid #ced4da" }}
                        className="form-select"
                        aria-label="Default select example">
                         <option unselectable>{sub_id && "Choose Category"}</option>
                        {
                            products.length ? products.map((data, i) => {
                            return (
                                <option aria-required value={i}>{data.name}</option>
                            )
                            }):<option aria-required value={''} disabled>No Product Found</option>
                        }
                        </select>
                </FormControl>
                {/* <textarea value={ContentDe} className="form-control" onChange={(e) => setContentDe(e.target.value)}  style={{border:"1px solid #233044",padding:"10px"}} id="exampleFormControlTextarea1" rows="10"></textarea> */}
                </div>
               
            </div>
            
            </div>
        </div>
    )
}

function JournalUpdate({ theme }) {
  const location = useLocation();
  const [type, settype] = useState(location?.state?.action)
  const TableCellStyle = { border: "1px solid red" };
  const [select, setselect] = useState(true);
  const [open, setOpen] = React.useState(false);
  // const [loader, setloader] = React.useState(false);
  const [nameen, setnameen] = React.useState("");
  const [namege, setnamege] = React.useState("");
  const [writer, setWriter] = React.useState("");
  const [reviewer, setreviewer] = React.useState("");
  const [ContentDe, setContentDe] = React.useState("");
  const [ContentEn, setContentEn] = React.useState("");
  const [images, setimages] = useState([])
  const [writerImg, setWriterImg] = useState('')
  const [mdData, setmdData] = useState([{
      en:'',
      de:'',
      product:{}
  }])
  const [article_id, setArticle_id] = useState('')
  const [selectedCateg, setselectedCateg] = React.useState('');
  const [editChoice, seteditChoice] = React.useState("");
  const [multipleImg, setmultipleImg] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [msg, setmsg] = React.useState("");
  const [refen, setrefen] = useState("");
  const [refde, setrefde] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectgrp, setselectgrp] = React.useState([]);
  const [data, setdata] = useState([
      {no:"1",question_name:"What if my order is lost, damaged or incomplete?",category:"Orders & Subscriptions",createDate:"10.21.2021"}
  ]);
  const [state, setState] = React.useState({
    open1: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const classes = useStyles();

  const [loader, setloader] = React.useState(false);
  const { vertical, horizontal, open1 } = state;

console.log(mdData)
  const handleClick1 = (newState) => {
    // console.log("handle click")
    setState({ open1: true, ...newState });
  };

  const handleClose1 = () => {
    setState({ ...state, open1: false });
  };

//   const handleChangeSelect = (event) => {
//     // console.log(event.target.value ,"workingg")
//     var stringArray = event.target.value
//     // console.log(selected,"selected")
//     // console.log(event.target.value ,"workingg")
//     // console.log(stringArray[2] ,"workingg")

//     setselected(stringArray);
//   };

  useEffect(async () => {
    await getCategory()
    // 
   console.log(location.state._id,"locc")
//    location?.state?.action ? getfaqdetails() : null
  }, []);
 
  useEffect(() => {
    let data = location.state
    if(data){  
      getTreatmentGuideByID()
    }
  }, []);

  const getTreatmentGuideByID = async () => {
    setloader(true);
    let data = location.state;
    const dataToSend = {
      journal_id: location.state._id,
      lang: 'en'
    }
    console.log('data to send', dataToSend)  
    await get(URL.getTreatmentGuideByID,dataToSend).then((res)=> {
      console.log('dataaaa', res.data); 
        res.data?.written_by && setWriter(res.data?.written_by?.name)
        res.data?.written_by && setWriterImg(res.data?.written_by?.image)
        res.data?.written_by && setreviewer(res.data?.written_by?.name)
        res.data?.category_id && setselectedCateg(res.data?.category_id)
        res.data?.name?.en && setnameen(res.data?.name?.en)
        res.data?.name?.de &&  setnamege(res.data?.name?.de)
        res.data?._id && setArticle_id(res.data?._id)
        res.data?.images && setimages(res.data?.images)
        res.data?.references?.en && setrefen(res.data?.references?.en)
        res.data?.references?.de && setrefde(res.data?.references?.de)
        if(res.data?.content){
          let arr =[]
          let counter = 1;
          let total = Object.keys(res.data.content.en).length
          let possibleItems = Math.ceil(total/2) 
          for (let i = 1; i <= possibleItems; i++) {
              let temp={
                  en:res.data?.content?.en[`${i}_md`] ? res.data?.content?.en[`${i}_md`] : '',
                  de:res.data?.content?.de[`${i}_md`] ? res.data?.content?.de[`${i}_md`] : "",
                  product:res.data.content.en[`${i}_product`] ? res.data.content.en[`${i}_product`] : {}
              }
              arr.push(temp)
          } 
          console.log(arr)
          setmdData(arr)
      }
    })
    .catch((e) => console.log('Error', e))
  }

  const getCategory=async()=>{
    await get(URL.getArticleTreatmentGuideCategory).then((res) => {
        if (res.status === true) {
            var lang = store.getState().authReducer.lang
            var array = []
             res.data.map((data, i) => {
            //   var d = data.default
              var id = data._id
              var name = data.name['en']
              array.push({ id, name })
            //   if (data.name[lang]) {
            //   } else {
            //     var id = data._id
            //     var name = data.name['en']
            //     array.push({ id, name })
            //   }
              return array
            })
            console.log(array, "array")
            setselectgrp(array)
            setloader(false)
          } 
    })
      .catch((error) => {
        // alert(error)

        setloader(false)
      })
  }

// const getfaqcategory=async()=>{
//     await get(URL.getfaqcategory).then((res) => {
//         // console.log(res, "resss")
//         if (res.status === true) {
//           var lang = store.getState().authReducer.lang
//           var array = []
//            res.data.map((data, i) => {
//             var d = data.default
//             if (data.name[lang]) {
//               var id = data._id
//               var name = data.name[lang]
//               array.push({ id, name })
//             } else {
//               var id = data._id
//               var name = data.name[d]
//               array.push({ id, name })
//             }
//             return array
//           })
//         //   console.log(array, "array")
//           setselectgrp(array)
//           setloader(false)
//         } 
//       })
// }
const addDetails=()=>{
    if(!nameen || !namege || !writer || !reviewer || !writerImg || !selectedCateg || !images.length || !refen || !refde){
        enqueueSnackbar("All feilds and images are required",{
            variant:'error'
          })
        return;
    }

    let content = {en:{},de:{}}
    let idx= -1
    mdData?.map((data,i)=>{
        content.en[`${i+1}_md`] = data.en
        content.de[`${i+1}_md`] = data.de
        content.en[`${i+1}_product`] = data?.product ? {
             name: data?.product?.name?.en,
            image: data?.product?.product_image,
            product_id: data?.product?._id,
            description: data?.product?.description?.en
        }:{}
        content.de[`${i+1}_product`] = data.product ? {
             name: data?.product?.name?.de,
            image: data?.product?.product_image,
            product_id: data?.product?._id,
            description: data?.product?.description?.en
        }:{}

    })

    if(type == 'edit'){
        editDetails(content)
    }else{
        addNewJournal(content)
    }
    return;
//   if(!nameen){ return  enqueueSnackbar("Please enter name eng",{
//     variant:'error'
//   })}
//   else  if(!namege){ return  enqueueSnackbar("Please enter name de",{
//     variant:'error'
//   })}
//   else  if(!ContentEn){ return  enqueueSnackbar("Please enter content de",{
//     variant:'error'
//   })}
//   else  if(!ContentDe){ return  enqueueSnackbar("Please enter content de",{
//     variant:'error'
//   })}
    var data = {
        content: {
            en :{},
            de: {}    
        },
        name: {
            en: nameen,
            de :namege
        } ,
        images: images,
        category_id: selectedCateg,
        written_by:{
            image:writerImg,
            name: writer,
            reviewed_by: reviewer
        },
    }
    console.log(data)
    return 
    setloader(true)
     post(URL.addfaqquestion,data,headers)
    .then((response) => {
      console.log("res123", response);
       if (response.status === true) {
        enqueueSnackbar(response.msg,{
          variant:'success'
          })
        setOpen(false)
        setloader(false)
      } else {
        enqueueSnackbar(response.msg,{
          variant:'success'
          })
          setloader(false)
      }
    })
    .catch((error) => {
       setOpen(false)
      const message = error.message || "Something went wrong";
      enqueueSnackbar(message,{
        variant:'error'
        })
      setloader(false)
    });
}

const editDetails = async (content)=>{
 
  // let content = {en:{},de:{}}
  //   mdData.map((data,i)=>{
  //       content.en[`${i+1}_md`] = data.en
  //       content.de[`${i+1}_md`] = data.de
  //       content.en[`${i+1}_product`] = data?.product ? {
  //            name: data?.product?.name?.en,
  //           image: data?.product?.product_image,
  //           product_id: data?.product?._id,
  //           description: data?.product?.description?.en
  //       }:{}
  //       content.de[`${i+1}_product`] = data.product ? {
  //            name: data?.product?.name?.de,
  //           image: data?.product?.product_image,
  //           product_id: data?.product?._id,
  //           description: data?.product?.description?.en
  //       }:{}

  //   })
    let dataToSend = {
        "content": content,
        journal_id: article_id,
        category_id:selectedCateg,
        name: {
            en: nameen ,
            de: namege
        } ,
        references:{
          en:refen,
          de:refde
        },
        images: images,
        category_id: selectedCateg,
        written_by:{
            image:writerImg,
            name: writer,
            reviewed_by: reviewer
        },
        products:[
            {
                "id":"618a304d0b350729f17cce3f",
                "name":"biotin",
                "image": "https://c8.alamy.com/comp/EEKWEM/studio-shot-of-erectile-dysfunction-drug-cialis-tadalafil-EEKWEM.jpg"
            }   
        ]
    }
    console.log(dataToSend)
    // return 
    await post(URL.updateArticleTreatmentGuide,dataToSend).then(res=>{
      console.log('response',res)
      if(res.status){
        enqueueSnackbar("Article updated successfully",{
          variant:'success'
        })
      }
      console.log('edit')
}).catch(err=>{
    console.log(err)
    let message = err?.msg || 'Something went wrong'
    enqueueSnackbar(message,{
        variant:'error'
      })
})
  
}

// "name": "Product Name",
// "image": "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.at-languagesolutions.com%2Fen%2Fatblog%2Ftraduccion-url-seo%2F&psig=AOvVaw24LjIkH-ZahKWyvnMSEXYt&ust=1639561978333000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCNj74L2C4_QCFQAAAAAdAAAAABAD",
// "product_id":"618a304d0b350729f17cce3f",
// "description": "Let keep you hair on you head"

const addNewJournal = async(content)=>{
    // let content = {en:{},de:{}}
    // mdData.map((data,i)=>{
    //     content.en[`${i+1}_md`] = data.en
    //     content.de[`${i+1}_md`] = data.de
    //     content.en[`${i+1}_product`] = data?.product ? {
    //          name: data?.product?.name?.en,
    //         image: data?.product?.product_image,
    //         product_id: data?.product?._id,
    //         description: data?.product?.description?.en
    //     }:{}
    //     content.de[`${i+1}_product`] = data.product ? {
    //          name: data?.product?.name?.de,
    //         image: data?.product?.product_image,
    //         product_id: data?.product?._id,
    //         description: data?.product?.description?.en
    //     }:{}

    // })
    let dataToSend = {
        "content": content,
        name: {
            en: nameen ,
            de: namege
        } ,
        references:{
          en:refen,
          de:refde
        },
        images: images,
        category_id: selectedCateg,
        written_by:{
            image:writerImg,
            name: writer,
            reviewed_by: reviewer
        },
        products:[
            {
                "id":"618a304d0b350729f17cce3f",
                "name":"biotin",
                "image": "https://c8.alamy.com/comp/EEKWEM/studio-shot-of-erectile-dysfunction-drug-cialis-tadalafil-EEKWEM.jpg"
            }   
        ]
    }
    await post(URL.addArticleTreatmentGuide,dataToSend).then(res=>{
        if(res.status){
            enqueueSnackbar("Article created successfully",{
                variant:'success'
            })
        }
    }).catch(err=>{
        console.log(err)
        let message = err?.msg || 'Something went wrong'
        enqueueSnackbar(message,{
            variant:'error'
          })
    })
    console.log(content)
}

// const getfaqdetails=()=>{
//          location.state.question ? setnameen(location.state.question[store.getState().authReducer.lang]) : null
//         location.state.answer ? setContentEn(location.state.answer[store.getState().authReducer.lang]) : null
//         location.state.question ? setnamege(langFun(location.state.question)) : null
//         location.state.answer ? setContentDe(langFun(location.state.answer)) : null   
//         location.state.faq_category_id ? setselected(location.state.faq_category_id) : null
//         selectgrp.filter((data1,i)=> data1.id == location.state.faq_category_id)
//         .map((data2,i)=>{
//             return seteditChoice(data2)
//         })
// }


const handleMultipleUpload = async (files) =>{
    // console.log('upload',files)

    // return
    let name=''
    if(nameen){
      name = strToSlug(nameen)
    }else{
      name = UniqueIdGen(5)
    }
    var formData = new FormData();
    formData.append('path', `pathOfs3/products/${name}`);
    files.map(file=> formData.append('files', file))
    // formData.append('files', files);
    // let dataToSend = {
    //   file:files[0],
    //   path:`pathOfs3/products/${name}`
    // }
    // console.log('data to send',dataToSend)bfore fn call
    // return;
    await post(URL.UploadMultiple,formData).then(res=>{
      if(res.status){
        console.log('upload response',res.data)
        let imgs=[...images,...res.data]
        setimages(imgs)
        // formik.setFieldValue('gallery',res?.data)
        setmultipleImg(false)
      }
    }).catch(err=>{
      let msg = err.msg || 'Something went wrong' 
      // console.log('upload response error',msg)
      enqueueSnackbar(msg,{
        variant:'error'
      })
    })
  }

  const handleSingleUpload = async (files) =>{
    console.log('upload',files[0])
    // return
    let name=''
    if(writer){
      name = strToSlug(writer)
    }else{
      name = UniqueIdGen(5)
    }
    var formData = new FormData();
    formData.append('path', `pathOfs3/writer/${name}`);
    formData.append('file', files[0]);
    // let dataToSend = {
    //   file:files[0],
    //   path:`pathOfs3/products/${name}`
    // }
    // console.log('data to send',dataToSend)
    // return;
    await post(URL.UploadSingle,formData).then(res=>{
      if(res.status){
        console.log('upload response',res.data)
        setWriterImg(res.data)
        // formik.setFieldValue('singleImg',res.data)
        // setsingleImg(false)

      }
    }).catch(err=>{
      let msg = err.msg || 'Something went wrong' 
      // console.log('upload response error',msg)
      enqueueSnackbar(msg,{
        variant:'error'
      })
    })
  }

  const AddContent = () =>{
      let temp={
        en:'',
        de:'',
        product:{}
    }
    let arr=[...mdData]
    arr.push(temp)
    setmdData(arr)
  }

  const RemoveImage = (idx)=>{
    let imgs=[...images]
    imgs.splice(idx,1)
    setimages(imgs)
  }

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography
            style={{ color: "rgb(17, 24, 39)" }}
            variant="h3"
            gutterBottom
          >
            Article
          </Typography>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open1}
          onClose={handleClose1}
          message={msg}
          key={vertical + horizontal}
        />
        {/* <Grid item>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder="Search topics" />
          </Search>
        </Grid> */}
      </Grid>
      <Grid item>
        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
      </Grid>

    <h5>Details</h5>
            <Grid container justify="center">
                <Grid item sm={6} style={{display:'flex',justifyContent:'center'}}>
                    <Avatar src={writerImg} style={{height:110,width:110}}/>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item sm={6} style={{display:'flex',justifyContent:'center'}}>
                    {/* <div className="my-3">
                    <Button onClick={() => setmultipleImg(true)} variant='contained'>Writer Image</Button>
                    </div> */}
                    <input
                        accept="image/*"
                        className={'d-none'}
                        id="contained-button-file"
                        // multiple
                        type="file"
                        onChange={(e)=>handleSingleUpload(e.target.files)}
                    />
                    <label htmlFor="contained-button-file" style={{marginTop:15}}>
                        <Button variant="contained" color="primary" component="span">
                        Writer image
                        </Button>
                    </label>
                </Grid>
            </Grid>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                Written by
                </label>
                <input
                onChange={(e) => setWriter(e.target.value)}
                type="text"
                value={writer}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Written by"
                />
          </div>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                Reviewed by
                </label>
                <input
                onChange={(e) => setreviewer(e.target.value)}
                type="text"
                value={reviewer}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Reviewed by"
                />
          </div>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                Name Eng
                </label>
                <input
                onChange={(e) => setnameen(e.target.value)}
                type="text"
                value={nameen}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Name Eng"
                />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Name German
            </label>
            <input
              onChange={(e) => setnamege(e.target.value)}
              type="text"
              value={namege}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Name German"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              References en
            </label>
            <input
              onChange={(e) => setrefen(e.target.value)}
              type="text"
              value={refen}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="References en"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
                References de
            </label>
            <input
              onChange={(e) => setrefde(e.target.value)}
              type="text"
              value={refde}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="References de"
            />
          </div>
          
            {/* {
                images.length ? images.map(img=>{
                    return <img src={img} style={{width:90,height:90}} />
                }):null
            } */}
            <div className="d-flex ">
            {
                images.length ? images.map((img,i)=>{
                    return <div className="mr-2" style={{position:'relative'}}>
                      <div style={{height:'100%',width:'100%',background:'rgba(0,0,0,0.5)',position:'absolute',top:0,left:0}}></div>
                    <img src={img} style={{width:90,height:90}} />
                    <p style={{position:'absolute',top:2 ,right:10,color:'white'}} onClick={()=>RemoveImage(i)}>X</p>
                  </div>
                }):null
            }
            </div>
            <div className="mb-3">
              <p >Product Gallery</p>
              <Button onClick={() => setmultipleImg(true)} variant='contained'>Product Gallery</Button>
            </div>
          <FormControl fullWidth >
                <label htmlFor="exampleFormControlInput1" className="form-label">
                Category
                </label>
                {/* <InputLabel  id="demo-simple-select-label">Choose Gropu for this Sub Group</InputLabel> */}
                <select
                  onChange={(e) => setselectedCateg(e.target.value)}
                  value={selectedCateg}
                  style={{ height: "36px", border: "1px solid #ced4da" }}
                  className="form-select"
                  aria-label="Default select example">
                  <option unselectable>{editChoice ? editChoice.name :"Choose Category"}</option>
                  {
                    selectgrp.map((data, i) => {
                      return (
                        <option aria-required value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </FormControl>
        
      <Grid item>
        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
      </Grid>
    

      <h5>2.Content</h5>
      <div className="container-fluid ">
        <div className="row">
            <div className="col-12">
                
            {/* <p style={{color:"#233044"}}>Content Eng</p>
                <div className="mb-3">
                    <RichTextEditor
                        value={cen}
                        onChange={setcen}
                    />
            </div>
        
                <p style={{color:"#233044",marginTop:"17px"}}>Content German</p>
                <div className="mb-3">
                    <RichTextEditor
                        value={cen}
                        onChange={setcen}
                    />
                </div>
                <br></br><br></br> */}
                {
                    mdData.length > 0 && mdData.map((data,i)=>{
                        return <div><MdBox key={data} data={data} setmdData={setmdData} index={i} mdData={mdData}/></div>
                    })
                }
                
                {/* <button onClick={()=> addDetails()} type="button" className="btn btn-primary" style={{marginTop:"17px",backgroundColor:"rgb(17,24,39)",borderColor:"rgb(17,24,39)"}}>SAVE CHANGES</button>
                <button type="button" className="btn btn-primary" style={{marginTop:"17px",backgroundColor:"#F7F9FC",color:"rgb(17,24,39)",borderColor:"gray",marginLeft:"5px",width:"14%"}}>CANCEL</button> */}
            </div>
            
            </div>
        </div>
                <Grid container justify="center">
                    <Grid item sx={4}>
                        <Button onClick={AddContent} variant="contained" fullWidth>Add More Content</Button>
                    </Grid>

                </Grid>
        <div className="row">
            <div className="col-12"> 
                <button onClick={()=> addDetails()} type="button" className="btn btn-primary" style={{marginTop:"17px",backgroundColor:"rgb(17,24,39)",borderColor:"rgb(17,24,39)"}}>SAVE CHANGES</button>
                {/* <button type="button" className="btn btn-primary" style={{marginTop:"17px",backgroundColor:"#F7F9FC",color:"rgb(17,24,39)",borderColor:"gray",marginLeft:"5px",width:"14%"}}>CANCEL</button> */}
            </div>
            
        </div>
    
    <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        initialFiles={images}
        open={multipleImg}
        onClose={() => setmultipleImg(false)}
        onSave={(files) => {
          console.log('Files:', files);
          handleMultipleUpload(files)
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    
    
    </React.Fragment>
  );
}

export default withTheme(JournalUpdate);
