import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  User,
} from "react-feather";
import Details from "../pages/components/Details";  
import JournalUpdate from "../pages/components/JournalUpdate";
import TreatmentGuidesUpdate from "../pages/components/TreatmentGuidesUpdate";
import UserOrders from "../pages/components/UserOrders";
import UserOrderDetails from "../pages/components/UserOrderDetails";
import Coupons from "../pages/pages/Coupons";
import CouponDetails from "../pages/pages/CouponDetails";
import AnalyticaComp from "../pages/pages/AnalyticaComp";
import Currencies from "../pages/pages/Currencies";
import SubGroupDet from "../pages/pages/SubGroupDet";
import BulkUpload from "../pages/pages/BulkUpload";
import BulkImages from "../pages/pages/BulkImages"; 

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const ProductDetails = async(() => import("../pages/components/ProductDetails"));
const ProductSlug = async(() => import("../pages/components/ProductSlug"));
const FaqQuestion = async(() => import("../pages/components/FaqQuestion"));
const FaqQuestionDetails = async(() => import("../pages/components/FaqQuestionDetails"));
const Badges = async(() => import("../pages/components/ProductInstruction"));
const ProductInstruction = async(() => import("../pages/components/ProductInstruction"));
const Buttons = async(() => import("../pages/components/Buttons"));
const ProductTestiomnials = async(() => import("../pages/components/ProductTestiomnials"));
const Cards = async(() => import("../pages/components/Cards"));
const SafetyInformation = async(() => import("../pages/components/SafetyInformation"));
const Chips = async(() => import("../pages/components/Chips"));
const HowItWorks = async(() => import("../pages/components/HowItWorks"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ProductFaq = async(() => import("../pages/components/ProductFaq"));
const ProductFaq2 = async(() => import("../pages/components/ProductFaq2"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Questionnarie = async(() => import("../pages/components/Questionnarie"));
const GetQuestionnaire = async(() => import("../pages/dashboards/Analytics/GetQuestionnaire"));
const Doctor = async(() => import("../pages/dashboards/Analytics/Doctor"));
const UpdateDoctor = async(() => import("../pages/dashboards/Analytics/UpdateDoctor"));
const GetQuestionnaireDetail = async(() => import("../pages/dashboards/Analytics/GetQuestionnaireDetail"));
const SubQuestion = async(() => import("../pages/dashboards/Analytics/SubQuestion"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));
const Science = async (() => import('../pages/components/Science'));
const ScienceDetails = async (() => import('../pages/components/ScienceDetails'));
const Media = async (() => import("../pages/components/Media"));
const MediaDetails = async (() => import("../pages/components/MediaDetails"));

// Dashboards components
// const Default = async(() => import("../pages/dashboards/Default"));
const Subgroup = async(() => import("../pages/dashboards/Analytics/Subgroup"));
const Group = async(() => import("../pages/dashboards/Analytics/Group"));
const GroupDetails = async(() => import("../pages/dashboards/SaaS/GroupDetails"));
const Subscription = async(() => import("../pages/dashboards/Analytics/Subscription"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const ScientificInfo = async(() => import("../pages/forms/ScientificInfo"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));
const Formik = async(() => import("../pages/forms/Formik"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const TreatmentGuides = async(() => import("../pages/pages/TreatmentGuides"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const ProductList = async(() => import("../pages/pages/ProductList"));
const Settings = async(() => import("../pages/pages/Settings"));
const SubGroupDetails = async(() => import("../pages/pages/SubGroupDetails"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));
const PageMedia = async(() => import("../pages/pages/PageMedia"));
const PageMediaDetails = async(() => import("../pages/pages/PageMediaDetails"));
const Chat = async(() => import("../pages/pages/Chat"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));
const DataGrid = async(() => import("../pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(() =>
  import("../pages/docs/EnvironmentVariables")
);
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const APICalls = async(() => import("../pages/docs/APICalls"));
const ESLintAndPrettier = async(() =>
  import("../pages/docs/ESLintAndPrettier")
);
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));

// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const dashboardsRoutes = {
  id: "Product",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  // containsHome: true,
  children: [
    {
      path: "/dashboard/product",
      name: "Product",
      component: Group,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/productsubgroups",
      name: "Product Sub Groups",
      component: Subgroup,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/product/list",
      name: "Product List",
      component: ProductList,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/product/bulk_upload",
      name: "Bulk Upload Products",
      component: BulkUpload,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/product/bulk_images_upload",
      name: "Bulk Upload Images",
      component: BulkImages,
      guard: AuthGuard,
    },
    // {
    //   path: "/coupons/details",
    //   name: "Coupon Details",
    //   component: CouponDetails,
    // },

  ],
  component: null,
};

const MediaPageRoutes = {
  id: 'Media',
  path: '/media',
  icon: <CheckSquare />, 
  component: Media,
  children:  null,
}

const MediaDetailsRoutes = {
  id: 'mediaPage',
  path: '/media/details',
  icon: <CheckSquare />, 
  component: MediaDetails,
  children:  null,
}

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: SubGroupDetails,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
  component: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null,
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Details",
      component: InvoiceDetails,
    },
  ],
  component: null,
};

const orderRoutes = {
  id: "Order",
  path: "/orders",
  icon: <User />,
  component: UserOrders,
  // children: [
  //   {
  //     path: "/paid_orders",
  //     name: "Paid Orders",
  //     component: InvoiceList,
  //   },
  //   {
  //     path: "/on_hold_orders",
  //     name: "On hold orders",
  //     component: InvoiceDetails,
  //   },
  // ],
  guard: AuthGuard,
};

const orderDetails = {
  id: "Order",
  path: "/orders/details",
  icon: <User />,
  component: UserOrderDetails,
  // children: [
  //   {
  //     path: "/paid_orders",
  //     name: "Paid Orders",
  //     component: InvoiceList,
  //   },
  //   {
  //     path: "/on_hold_orders",
  //     name: "On hold orders",
  //     component: InvoiceDetails,
  //   },
  // ],
  guard: AuthGuard,
};

const ResourceRoute = {
  id: "Rescource",
  path: "/resources",
  icon: <User />,
  component: null,
  children: [
    {
      path: "/treatment-guides",
      name: "Treatment Guides",
      component: TreatmentGuides,
      guard: AuthGuard,
    },
    {
      path: "/journal",
      name: "Journal",
      component: Orders,
      guard: AuthGuard,
    },
    {
      path: "/faqs",
      name: "Faq's",
      component: FaqQuestion,
      guard: AuthGuard,
    },
    {
      path: "/science",
      name: "Science",
      component: Science,
      guard: AuthGuard,
    },
    {
      path: "/glossary",
      name: "Glossary",
      component: InvoiceDetails,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

const Detail = {
  id: "Rescource",
  path: "/details",
  icon: <User />,
  component: Details,
  children: null,
  guard: AuthGuard,
};

const ProductRoute = {
  id: "Products",
  path: "/product",
  icon: <User />,
  component: null,
  children: [
    {
      path: "/dashboard/product/details",
      component: GroupDetails,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/productsubgroups/details",
      component: Settings,
    }, 
    {
      path: "/dashboard/productsubgroup/details",
      component: SubGroupDet,
      guard: AuthGuard
    },
    {
      path: "/components/productdetails",
      component: ProductDetails,
      guard: AuthGuard,
    },
    {
      path: "/components/product-slug",
      component: ProductSlug,
      guard: AuthGuard,
    },
    {
      path: "/components/productinstruction",
      component: ProductInstruction,
      guard: AuthGuard,
    },
    {
      path: "/components/producttestimonials",
      component: ProductTestiomnials,
      guard: AuthGuard,
    },  
    {
      path: "/components/safety/information",
      component: SafetyInformation,
      guard: AuthGuard,
    },  
    {
      path: "/components/howitworks",
      component: HowItWorks,
      guard: AuthGuard,
    },
    {
      path: "/components/faq",
      component: ProductFaq,
      guard: AuthGuard,
    },
    {
      path: "/components/faq2",
      component: ProductFaq2,
      guard: AuthGuard,
    },
    {
      path: "/components/scientificinfo",
      component: ScientificInfo,
      guard: AuthGuard,
    },
    {
      path: "/components/expansion-panels",
      component: Questionnarie,
      guard: AuthGuard,
    },
    {
      path: "/coupons/details",
      name: "Coupon Details",
      component: CouponDetails,
      guard: AuthGuard
    },
  ],
  guard: AuthGuard,
};

const SubscriptionRoutes = {
  id: "Subscriptions",
  path: "/subscriptions",
  icon: <CheckSquare />,
  badge: "17",
  children: [
    {
      path: "/dashboard/subscription",
      component: Subscription,
      name:"Subscription",
      guard: AuthGuard,
    },
  ]
};

const AnalyticsRoutes = {
  id: "adminAnalytics",
  path: "/analytics",
  icon: <CheckSquare />,
  component:AnalyticaComp,
  children: null
};

const mediaRoutes = {
  id: "Questionnaire",
  path: "/dashboard",
  icon: <CalendarIcon />,
  component: PageMedia,
  children: [
    {
      path: "/dashboard/questionnaire",
      component: GetQuestionnaire,
      guard: AuthGuard,
      name:"Questionnaire",
    },
  ]
};
const doctorRoutes = {
  id: "Doctor",
  path: "/doctor",
  icon: <CalendarIcon />,
  component: Doctor,
  children: null
};
const UpdateDoctorRoutes = {
  path: "/doctor",
  // icon: <CalendarIcon />,
  // component: Doctor,
  children: [
    {
      path: "/doctor/update",
      component: UpdateDoctor,
      guard: AuthGuard,
    },
  ]
};
const subRoutes = {
  id: "CURRENCY/COUNTRY",
  path: "/currency",
  icon: <CalendarIcon />,
  component: Calendar,
  children: [
    {
      path: "/dashboard/get/questionnaire",
      component: GetQuestionnaireDetail,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/get/sub/question",
      component: SubQuestion,
      guard: AuthGuard,
    },
    {
      path: "/faqs/details",
      component: FaqQuestionDetails,
      guard: AuthGuard,
    },
    {
      path: "/science/details",
      component: ScienceDetails,
      guard: AuthGuard,
    },
    {
      path: "/journal/details",
      component: JournalUpdate,
      guard: AuthGuard,
    },
    {
      path: "/treatment-guides/details",
      component: TreatmentGuidesUpdate,
      guard: AuthGuard,
    },
    // {
    //   path: "/currencies",
    //   component: Currencies,
    //   guard: AuthGuard,
    // },

  ]
};

const couponRoutes = {
  id: "Coupons",
  path: "/coupons",
  icon: <CalendarIcon />,
  component: Coupons,
  guard: AuthGuard
  // children: null,
  // children: [
  //   {
  //     path: "/coupons",
  //     name: "Coupons",
  //     component: Coupons,
  //   },

  // ]
};

const currencyRoutes = {
  id: "Currency/Country",
  path: "/currencies",
  icon: <CalendarIcon />,
  component: Currencies,

};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs,
    },
    {
      path: "/components/expansion-panelss",
      name: "Expansion Panels",
      component: ExpPanels,
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      component: Formik,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable,
    },
    {
      path: "/tables/data-grid",
      name: "Data Grid",
      component: DataGrid,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null,
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
  guard: AuthGuard,
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Material App",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.0",
  icon: <List />,
  component: Changelog,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  ProductRoute,
  projectsRoutes,
  orderRoutes,
  doctorRoutes,
  invoiceRoutes,
  UpdateDoctorRoutes,
  AnalyticsRoutes,
  // tasksRoutes,
  // calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  couponRoutes,
  currencyRoutes, 
  subRoutes,
  MediaPageRoutes,    // Media Page Route 
  MediaDetailsRoutes, // Media Details Route
  SubscriptionRoutes,
  mediaRoutes,
  ResourceRoute,
  Detail,
  orderDetails
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  // pagesRoutes,
  // projectsRoutes,
  AnalyticsRoutes,
  orderRoutes,
  doctorRoutes,
  ResourceRoute,
  // ProductRoute,
  SubscriptionRoutes,
  mediaRoutes,
  currencyRoutes,
  // subRoutes,
  MediaPageRoutes,
  couponRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // authRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];
