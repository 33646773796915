import Axios from "axios";
import { lang } from "moment";
import LoginAction from "./redux/actions/authActions";
import { store } from "./redux/store/index";

// console.log('Store',store.getState().user.token)
export const URL = {
  login: "api/user/login",
  // //products
  getproducts: "api/products/productSearch",
  getProductByIds: "api/products/getProductByIds",
  //PRODUCSTS
  addProduct: "api/products/add",
  productSlugCheck: "api/products/checkSlug",
  productSlugUpdate: "api/products/changeSlug",
  //GET PRODUCT GROUP
  // getproductgroup:"https://prod.haelf.com/api/groups/getGroups?lang=en&offset=0&per_page=0",
  //ADD NEW GROUP
  addnewgroup: "api/groups/addGroup",
  //DELETE GROUP
  deleteGroup: "api/groups/deleteGroup",
  //DELETE SUB GROUP
  deleteSubGroup: "/api/groups/deleteSubGroup",
  //GET SUB GROUP
  // getsubgroup:"api/groups/getSubGroups?lang=en&offset=0&per_page=0",
  //ADD NEW SUB GROUP
  addnewsubgroup: "api/groups/addSubGroup",
  editGroup: "api/groups/editGroup",
  editSubGroup: "api/groups/editSubGroup",
  toggleGroup: "api/groups/groupVisibility",
  toggleSubGroup: "api/groups/subgroupVisibility",
  //GET PRODUCT LIST
  // getproductlist:"https://prod.haelf.com/api/products/getproducts?lang=en&per_page=30&offset=0&location=UK",
  getproductlist: "api/products/getproducts",
  getAdminproductlist: "api/products/admin/getproducts",
  //EDIT PRODUCT DETAILS
  editproductdetail: "api/products/updateProduct",
  //UPDATE PRODUCT INSTRUCTION
  updateproductinstruction: "api/products/updateProductInstruction",
  //UPDATE PRODUCT TESTIMOMNIALS
  updatetestimonials: "api/products/updateTestimonial",
  deleteTestimonial: "/api/products/deleteTestimonial",
  //ADD SAFETY INFO
  addsafetyinfo: "api/products/safetyInformation",
  //edit how it works
  edithowitwork: "api/products/works",
  //ADD PRODUCT FAQ
  addfaq: "api/products/faq",
  //EDIT PRODUCT FAQ
  editfaq: "api/products/udpatefaq",
  //ADD PRODUCT FAQ2
  addfaq2: "api/products/faq2",
  //EDIT PRODUCT FAQ2
  editfaq2: "api/products/udpatefaq2",
  //SCIENTIFIC INFO
  scientific_info: "api/products/scientificInfo",
  //getQuestionGroup
  getquestiongroup: "api/questions/getQuestionGroup",
  //ADD QUESTION
  addquestion: "api/questions/add",
  //ADD SUB QUESTION
  addsubquestion: "api/questions/addSubQuestion",
  //GET QUESTION GROUP
  getQuestionGroups: "/api/questions/getQuestionGroup",
  //ADD QUESTION GROUP
  addQuestionGroups: "/api/questions/groupAdd",
  deleteSubKey: "/api/questions/deleteSubsKey",
  toggleActive: "/api/questions/toggleActive",

  //ADD FAQ NEW CATEGORY
  addfaqnewcategory: "api/faq/categoryAdd",
  editfaqnewcategory: "api/faq/categoryEdit",
  deleteFaqCategory: "api/faq/categoryDelete",
  //GET FAQ CATEGORIES
  getfaqcategory: "api/faq/getCategory?offset=0&per_page=100&lang=en",
  //ADD FAQ QUESTION
  addfaqquestion: "api/faq/createFAQ",
  //GET FAQ
  getfaq: "api/faq/getFAQ?offset=0&per_page=10&lang=en",
  //UPDATE FAQ
  updatefaq: "api/faq/createFAQ",
  //UPDATE FAQ
  deleteFaq: "api/faq/deleteSingleFAQ",
  //Get FAQ details
  getFaqDetail: "api/faq/getFaqQuestion",

  // SCIENCE AND CATEGORY
  // GET API
  getScience: "api/science/get",
  getScienceCategory: "api/science/category/get",
  // EDIT API
  editScienceCategory: "api/science/category/update",
  editScience: "api/science/update",
  // Add API
  addNewScienceCategory: "api/science/category/add",
  addScience: "api/science/add",
  // DELETE API
  deleteScience: "api/science/delete",
  deleteScienceCategory: "api/science/category/delete",

  // Media
  // ADD API
  addMedia: "/api/media/add",
  // UPDATE API
  eidtMedia: "api/media/update",
  // GET API
  getMedia: "api/media/get?lang=en",

  // Product List
  // POST API
  searchProduct: "api/products/admin/searchproducts",

  // Get Data By ID
  //GET GROUP BY ID
  getGroupByID: "api/groups/getGroupsById",
  //GET SUB-GROUP BY ID
  getSubGroupByID: "api/groups/getSubGroupsById",
  //GET Journal BY ID
  getJournalByID: "api/journal/getJornalById",
  //GET TreatmentGuide BY ID
  getTreatmentGuideByID: "api/journal2/getJornalById",
  //GET Frequently Asked Questions BY ID
  getFAQByID: "",
  //GET SCIENCE BY ID
  getScienceByID: "api/science/getScienceById",
  //GET Glossary BY ID
  getGlossaryByID: "api/glossary/getGlossaryByID",
  //GET MEDIA BY ID
  getMediaByID: "api/media/getMediaById",
  //GET COUPONS BY ID
  getCoupounByID: "api/coupon/getCouponById",
  //GET ORDER BY ID
  getUserOrderByID: "api/orders/getOrderByID",

  //GET SUBGROUP
  getsubgroup: "api/groups/getSubGroupsAp",
  //GET GROUP
  getgroup: "api/groups/getGroupsAp",
  //GET PRODUCT DETAIL
  getproductdetail: "api/products/getproductsDetails?",
  //GET TESTIMONIAL
  gettestimonial: "api/products/getTestimonial?",
  //GET QUESTIONNAIRE QUESTION
  getquestionnaire_question: "api/questions/getAllQuestions",

  //UPLOAD IMAGES
  UploadSingle: "image/upload",
  UploadMultiple: "image/multiple/upload",

  //delete product faq
  deleteProductFaq: "api/products/deletefaq",
  getProductFaq: "api/products/getfaq",

  //delete product faq2
  deleteProductFaq2: "api/products/deletefaq2",
  getProductFaq2: "api/products/getfaq2",

  //questionaire
  deleteQuestionnaire: "api/questions/deleteQuestion",
  editQuestionnaire: "api/questions/edit",
  getNestedSubs: "api/questions/getNestedSubQuestion",
  userQuestionnaire: "api/doctor/admin/orders/questions",
  prodQuestionnaire: "/api/products/getProductQuestionnaire",

  //add product question
  AddProductQuestion: "api/products/addSingleQuestionProduct",

  //order and subscription
  getAllSubscriptions: "api/subscription/get",

  //glossary
  getGlossary: "api/glossary/get",
  addGlossary: "api/glossary/add",
  updateGlossary: "api/glossary/update",
  deleteGlossary: "api/glossary/delete",

  //article
  getArticleCategory: "api/journal/category/get",
  addArticleCategory: "api/journal/category/add",
  updateArticleCategory: "api/journal/category/update",
  deleteArticleCategory: "api/journal/category/delete",
  getArticle: "api/journal/get",
  addArticle: "api/journal/add",
  updateArticle: "api/journal/update",
  deleteArticle: "api/journal/delete",

  //article - treatment guide
  getArticleTreatmentGuideCategory: "api/journal2/category/get",
  addArticleTreatmentGuideCategory: "api/journal2/category/add",
  updateArticleTreatmentGuideCategory: "api/journal2/category/update",
  deleteArticleTreatmentGuideCategory: "api/journal2/category/delete",
  getArticleTreatmentGuide: "api/journal2/get",
  addArticleTreatmentGuide: "api/journal2/add",
  updateArticleTreatmentGuide: "api/journal2/update",
  deleteArticleTreatmentGuide: "api/journal2/delete",

  //ORDERS

  updateOrder: "api/orders/update",
  updateSubscriptionStatus: "api/subscription/update",

  refundOrder: "api/orders/refund",

  getUserOrder: "api/orders/get",
  sendToGerman: "api/order/sendToGerman",
  sendToBritish: "api/order/sendToBritish",
  // coupons
  getCoupons: "api/coupon/get",

  editCoupoun: "api/coupon/update",
  addCoupon: "api/coupon/add",
  deleteCoupon: "api/coupon/delete",

  //DOCTORES-----------
  getDoctor: "api/doctor/get/all",
  adddoctor: "api/doctor/register",
  updatedoctor: "api/doctor/updateInfo",

  //currency
  addCurrency: "api/currency/add",
  getCurrency: "/api/currency/getCurrency",
  deleteCurrency: "api/currency/deleteCurrency",
  updateCurrency: "/api/currency/updateCurrency",

  //getAnalytics
  getAnalytics: "/api/getAnalytics",

  //BulkUpload
  bulkUpload: "/api/products/bulkUpload",
  bulkImages: "/api/products/uploadImages",
};

export const headers = {
  "content-type": "application/json",
};
export const langFun = (getLang) => {
  let language = store.getState().authReducer.lang;
  // console.log(getLang,"serviceeeee")

  if (getLang[language]) {
    return getLang[language];
  } else {
    var a = Object.keys(getLang)[0];
    return getLang[a];
  }
};

export const get = async (endPoint, params = {}) => {
  let token = store.getState().authReducer.token;
  console.log(endPoint, params, "endpoint");

  if (params) {
    endPoint = `${endPoint}?${new URLSearchParams(params).toString()}`;
    // endPoint.search = new window.URLSearchParams(params);
    // endPoint = endPoint.toString()
  }
  return new Promise((resolve, reject) => {
    Axios.get(endPoint, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data.status === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        if (error.message == "Request failed with status code 401") {
          store.dispatch(LoginAction.getLogout());
        } else {
          reject(error.data);
        }
      });
  });
};

export const post = async (endPoint, data) => {
  let token = store.getState().authReducer.token;
  let language = store.getState().authReducer.lang;
  // console.log("endpointttt",endPoint,token)
  data.lang = language ? language : "en";
  return new Promise((resolve, reject) => {
    Axios.post(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        // console.log('res',response)
        if (response.data.status === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        if (error.message == "Request failed with status code 401") {
          store.dispatch(LoginAction.getLogout());
        } else {
          reject(error.data);
        }
        // console.log("login error", error);
      });
  });
};
